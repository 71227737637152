import React from "react"
import { graphql } from "gatsby"

import { Row } from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap-grid.min.css'

import SecondLayout from "../components/secondlayout"


const TermsTemplate = ({ data, pageContext: { lang, currentPath } }) => console.log('') || (

  <SecondLayout wpLang={lang} currentPath={currentPath} pageTitle={data.wordpressPage.yoast_title} pageMeta={data.wordpressPage.yoast_meta} featuredImagePath="above-min.png">
    <Row className="single-page">
    <div className="entry-content">
    <h1>1. DEFINITION</h1>
    <ol>
        <li>Deposit is an amount of Dagcoins that the Client deposits with Dagcoin Grow under the conditions specified by the Deposit Transaction;</li>
        <li>
            Deposit Transaction is a Transaction whereby the Client undertakes to deposit a certain amount of Dagcoins with Dagcoin Grow for an agreed period of time and Dagcoin Grow undertakes to refund an agreed amount of Dagcoins to the
            Client under the agreed Terms;
        </li>
        <li>Start Date of the Deposit is the day when the client deposits Dagcoins to Dagcoin Grow;</li>
        <li>Expiry Date of the Deposit is the day on which the agreed period of Deposit ends;</li>
        <li>Transaction Wallet is the Client’s Dagcoin Wallet that is used for settlements associated with the deposit transaction. A Transaction Wallet must comply with Know Your Customer requirements.</li>
    </ol>
    <h4>2. GENERAL PROVISIONS</h4>
    <ol>
        <li>
            These Terms and Conditions of Dagcoin Grow (hereinafter the Terms) shall regulate the relationship between Dagcoin Grow and the Client for executing Dagcoin Grow transactions and prescribing the procedure for such transactions.
        </li>
        <li>Dagcoin Grow Deposit Transactions are limited to the cryptocurrency Dagcoin [Dagcoin.org]. Dagcoin Grow does not accept any fiat currency or any other crypto currency.</li>
        <li>The returns in a Deposit Transaction are given in the cryptocurrency Dagcoin. Dagcoin Grow does not give returns in any fiat currency or any other cryptocurrency.</li>
    </ol>
    <h4>3. THE PROCEDURE OF A DEPOSIT TRANSACTION</h4>
    <ol>
        <li>The essential conditions for a Deposit Transaction order are:</li>
        <li>The amount of the Deposit;</li>
        <li>the Start Date of the Deposit;</li>
        <li>The interest rate paid on the Deposit by Dagcoin Grow;</li>
        <li>The Expiry Date of the Deposit;</li>
        <li>On the Expiry Date of the Deposit, Dagcoin Grow shall send the agreed amount of Dagcoins to the Client’s Transaction Wallet.</li>
    </ol>
    <h4>4. PREMATURE TERMINATION OF A DEPOSIT TRANSACTION</h4>
    <ol>
        <li>A Deposit Transaction is concluded for a specified term and will terminate on the Expiry Date of the Deposit.</li>
        <li>
            A client can order premature termination of Deposit Transaction by sending a mail to Dagcoin Grow. Dagcoin Grow shall terminate the Deposit Transaction and send the number of coins deposited by the Client to his transaction
            Wallet.
        </li>
        <li>
            In the case of premature termination of the Deposit Transaction, Dagcoin Grow will not give any additional coins to the client. Client agrees that he/she shall not claim any additional coins if he/she orders pre mature
            termination.
        </li>
    </ol>
    <h4>5. CHANGE OF TRANSACTION WALLET</h4>
    <ol>
        <li>
            Dagcoin Grow will give coins on the expiry date of the deposit to the Client’s Transaction Wallet registered with Dagcoin Grow. Change of Transaction Wallet is generally not permitted. Dagcoin Grow may allow change of
            Transaction Wallet in exceptional circumstances.
        </li>
    </ol>
</div>


    </Row>
  </SecondLayout>
)
export const query = graphql`
  query($lang: String) {
    wordpressPage(
      slug: {eq: "terms-and-conditions"}
      wpml_current_locale: {eq: $lang}
    ) {
    acf {
      faq_content
      faq_content_1
      didnt_find_an_answer_go_to_support_page
      faq_title_3
      faq_content_3
      faq_content_9
      faq_content_2
      faq_content_4
      faq_content_5
      faq_content_6
      faq_content_7
      faq_content_8
      faq_title
      faq_title_1
      faq_title_2
      faq_title_4
      faq_title_5
      faq_title_6
      faq_title_7
      faq_title_9
      faq_title_8
      frequently_asked_questions
      grow_tile
      home_desc
      home_title
      how_1_desc
      how_1_title
      how_2_desc
      how_3_desc
      how_2_title
      how_3_title
      how_do_grow
      how_it_works
      how_to_desc
      piority_desc
      piority_title
      sign_up
      start_growing
      start_now
      time_to_start
      time_to_start_1_desc
      time_to_start_1_title
      time_to_start_2_desc
      time_to_start_2_title
      time_to_start_3_desc
      time_to_start_3_title
    }
      title
      yoast_meta {
        name
        content
        property
      }
      yoast_title
        wpml_current_locale
    }
    Videoimg: file(relativePath: { eq: "video-min.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }


    SendAs: file(relativePath: { eq: "mac2.png" }) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    GetAs: file(relativePath: { eq: "card2.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    play: file(relativePath: { eq: "play-button.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    right: file(relativePath: { eq: "right.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    Hand: file(relativePath: { eq: "hand.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    Custom_one: file(relativePath: { eq: "1.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    Custom_two: file(relativePath: { eq: "2.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    Custom_three: file(relativePath: { eq: "3.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }    
    Custom_four: file(relativePath: { eq: "4.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    arrow_right: file(relativePath: { eq: "right.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }


  }
`
export default TermsTemplate